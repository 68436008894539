import {
  SIGNUP_CUSTOMER,
  SIGNUP_CUSTOMER_SUCCESS,
  SIGNUP_CUSTOMER_FAIL
} from "./actionTypes"

export const signUpCustomer = (values) => ({
  type: SIGNUP_CUSTOMER,
  payload: values,
})

export const signUpCustomerSuccess = (value) => ({
  type: SIGNUP_CUSTOMER_SUCCESS,
  payload: value,
})

export const signUpCustomerFail = (error) => ({
  type: SIGNUP_CUSTOMER_FAIL,
  payload: error,
})





