import React from "react";
import styles from './style.module.scss';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Checkbox } from 'antd';
const { TextArea } = Input;

const OrdersPaymentOption = () => {

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    return (
        <div className={styles.item_container}>
            <div className={styles.item_content}>
                <p className={styles.item_title}>Pizza</p>
                <div className={styles.item}>
                    <label>1 Chicken Tikka Pizza 10 </label>
                    <div className={styles.action}>
                        <EditOutlined className={styles.enidIcon} />
                        <DeleteOutlined className={styles.delIcon} />
                    </div>
                    <label>£5.00</label>
                </div>

                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>thisisatestaddon</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.10</label>
                    </div>
                </div>
                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>tuyentest</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.21
                        </label>
                    </div>
                </div>
                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>newcategory</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.10
                        </label>
                    </div>
                </div>
                <hr></hr>
                <div className={styles.price_container}>
                    <p className={styles.sub_total}>Sub Total :</p>
                    <p className={styles.sub_total}>£5.41</p>
                </div>
                <div className={styles.price_container}>
                    <p className={styles.total}>Total :</p>
                    <p className={styles.total}>£5.41</p>
                </div>
                <TextArea rows={2} placeholder="Special Instructions" maxLength={100} />
                <div className={styles.suchform}>
                    <label>Please mention in special instructions where to leave your food. For cash orders please leave payment in an envelope or other such form</label>
                    <label>Contact-less Delivery 
                        <Checkbox onChange={onChange} style = {{marginLeft: 10}}/>
                    </label>
                </div>
                <p className = {styles.points_text}> This order earned 5 Points </p>
                <p> Earn Points & Use Them Towards Your Orders </p>
            </div>
            
        </div>
    );
}

export default OrdersPaymentOption;
