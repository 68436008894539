import React from "react";
import styles from './style.module.scss';
import { Link } from 'react-router-dom';

const YummyProgress = () => {
    return (
        <section id='toptabs' className={styles.toptabs}>
            <div className={styles.tabs}>
                <ul>
                    <li className={styles.browse}>Search</li>
                    <li className={styles.browse}>Browse Shop</li>
                    <li className={styles.browse}>Create your order</li>
                    <li>Payment information</li>
                    <li>Confirm order</li>
                    <li>Receipt</li>
                </ul>
            </div>
            <div className={styles.mobtabs}>
                <ul>
                    <li className={styles.browse}><Link><span></span></Link></li>
                    <li className={styles.browse}><Link><span></span></Link></li>
                    <li className={styles.browse}><Link><span></span></Link></li>
                    <li><Link><span></span></Link></li>
                </ul>
            </div>
        </section>
    );
}

export default YummyProgress;
