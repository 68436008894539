import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthRoutes, NonAuthRoutes } from "./routes/allRoutes";
import HorizontalLayout from "./components/HorizontalLayout/";
import topSecret from './assets/images/top_secret.png';
import Authmiddleware from './routes/middleware/Authmiddleware'
import NonAuthmiddleware from './routes/middleware/NonAuthmiddleware'

function App() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <React.Fragment>
      <Router>
        <HorizontalLayout>
          <div className="top_secret_container">
            <div className={`card ${isOpen ? "open" : ""}`}>
              <h1>Get Now</h1>
              <p>Additional 5% discount when you top up your<br />Yummy Bank from your profile area!</p>
              <svg onClick={() => setIsOpen(!isOpen)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#ED1E79"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg>
            </div>
            <img className="top_secret_img" src={topSecret} alt="Top Secret" onClick={() => setIsOpen(!isOpen)} />
          </div>
          <Switch>
            
            {AuthRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                exact
              />
            ))}

            {NonAuthRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                component={route.component}
                key={idx}
                exact
              />
            ))}

          </Switch>
        </HorizontalLayout>
      </Router>
    </React.Fragment>
  );
}

export default App;
