import {
  SET_AUTH_USER
} from "./actionTypes"

const initialState = {
  loading: false,
  authUser: null,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      state = {
        ...state,
        authUser: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
