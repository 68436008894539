import { call, put, takeEvery } from "redux-saga/effects"
import { SIGNUP_CUSTOMER} from "./actionTypes"

import {signUpCustomerSuccess, signUpCustomerFail} from "./actions"
import { signUpRestaurantAsync, signUpCustomerAsyc } from "../../helpers/backend_helper"


function* signUpCustomer({payload: values}) {
  try {
    const response = yield call(signUpCustomerAsyc, values)
    if(response.success){
      yield put(signUpCustomerSuccess(response.result))
    } else {
      yield put(signUpCustomerFail(response.message))
    }
  } catch (error) {
    yield put(signUpCustomerFail(error.err.message))
  }
}

function* CustomerSaga() {
  yield takeEvery(SIGNUP_CUSTOMER, signUpCustomer)
}

export default CustomerSaga
