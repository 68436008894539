import React, { useEffect } from "react"
import { Route, useHistory } from "react-router-dom"
import jwt_decode from 'jwt-decode';
import { setAuthUser } from "../../store/actions";
import { useDispatch } from "react-redux";

const Authmiddleware = ({
  component: Component,
  isAuthProtected,
  ...rest
}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    try {
      const authUser = jwt_decode(localStorage.getItem('accessToken'))
      if (authUser) {
        dispatch(setAuthUser(authUser))
      } else {
        dispatch(setAuthUser(null))
        history.push('/login')
      }
    } catch (error) {
      dispatch(setAuthUser(null))
      history.push('/login')
    }
  }, [])

  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Component {...props} />
        )
      }}
    />
  )
}

export default Authmiddleware;
