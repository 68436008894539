import {
  SET_AUTH_USER
} from "./actionTypes"

export const setAuthUser = user => {
  return {
    type: SET_AUTH_USER,
    payload: user,
  }
}
