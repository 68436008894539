import { combineReducers } from "redux"
import Account from "./account/reducer"
import Restaurant from "./restaurant/reducer"
import Customer from './customer/reducer'

const rootReducer = combineReducers({
  Account,
  Restaurant,
  Customer
})

export default rootReducer
