import React from "react";
import MetaTags from "react-meta-tags"
import styles from '../assets/styles/paymentoption.module.scss';
import YummyProgress from '../components/YummyProgress'
import { CustomButton } from '../components/Custom/CustomButton'
import { useHistory } from "react-router-dom";
import PhoneInput from "antd-phone-input";
import OrdersPaymentOption from '../components/OrdersPaymentOption'


const PaymentOption = () => {
    const history = useHistory()
    return (
        <React.Fragment>
            <MetaTags>
                <title>Yummy Takeaways | Paymet Option</title>
            </MetaTags>
            <div className={styles.main_container}>
                <div className={styles.banner}>
                    <h1>Payment Option</h1>
                    <p>choose your payment</p>
                </div>
                <YummyProgress />
                <div className={styles.results}>
                    <div className={styles.backBtn}>
                        <CustomButton width={130} height={33} background="#EB1E78" onClick={() => history.push('/yummytestshop')}>Back</CustomButton>
                    </div>
                    <div className={styles.result_content}>
                        <div className="row">
                            <div className="col-md-7">
                                <div className={styles.result_left}>
                                    <h3 style={{ marginTop: 20 }}>Pickup information</h3>
                                    <p className={styles.description}>
                                        Yummy Takeaways Coventry TEST SHOP Restaurant Pickup 06-06-2023 ASAP
                                        571 Stoney Stanton Road Coventry West Midlands CV6 5GA
                                    </p>
                                    <div className={styles.phone}>
                                        <PhoneInput enableSearch placeholder="Mobile Number" style={{ background: '#f0f0f0' }} />
                                    </div>
                                    <div className={styles.payinfo}>
                                        <p>Payment Information</p>
                                        <hr />
                                    </div>
                                    <div className={styles.radiowrap}>
                                        <div className={styles.radio}>
                                            <input type="radio" id="delivery" name='orderopt' value="Delivery"></input>
                                            <label for="pay-card">Pay By Card (including Yummy Bank & loyalty points)</label>
                                        </div>
                                        <div className={styles.radio}>
                                            <input type="radio" name='orderopt' id="pickup" value="Pickup"></input>
                                            <label for="pay-cash">Cash On Pickup</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={styles.result_right}>
                                    <div className={styles.header}>
                                        <p>Your Order</p>        
                                    </div>
                                    <div className={styles.order_container}>
                                        <OrdersPaymentOption />
                                    </div>
                                    <div className = {styles.reviewBtn}>
                                        <CustomButton width={180} height={38} background="#00b279" onClick={() => history.push('/review-order')}>Review My Order</CustomButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>

    )
}

export default PaymentOption
