import React from 'react';
import Header from "./Header";
import Footer from './Footer';

const Layout = (props) => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <main >{props.children}</main>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
