import MetaTags from "react-meta-tags"
import React, { useState } from "react";
import styles from '../assets/styles/profile.module.scss';
import { useSelector, useDispatch } from "react-redux"

const Profile = (props) => {
  const dispatch = useDispatch()
  const { authUser } = useSelector(state => state.Account)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Profile</title>
      </MetaTags>
      <div className={styles.main_container}>
        <div className={styles.banner}>
          <h1>Profile</h1>
          <p>Manage your profile,address book, credit card and more</p>
        </div>
        <div className={styles.login_form_container}>
          <div className="container">
            
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Profile
