import MetaTags from "react-meta-tags"
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import styles from '../assets/styles/home.module.scss';
import orderAlone from '../assets/images/order_alone.png';
import orderAsGroup from '../assets/images/order_as_group.png';
import fullCt from '../assets/images/full_ct.png';
import step1 from '../assets/images/step1.png';
import step2 from '../assets/images/step2.png';
import step3 from '../assets/images/step3.png';
import step4 from '../assets/images/step4.png';
import Responsive from "../components/slider/ResponsiveSlider";
import ReviewSlider from "../components/Reviews/ReviewSlider";
import ArrowLeft from '../assets/images/arrow-left.png';
import ArrowRight from '../assets/images/arrow-right.png';

const Home = () => {
  const [postcode, setPostcode] = useState("");

  const sliderRef = useRef();

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Online Ordering & Takeaways</title>
      </MetaTags>
      <div className={styles.main_container}>
        <section className={styles.hero}>
          <div className={`${styles.bg_img} ${styles.bg_img_one}`} />
          <div className={`${styles.bg_img} ${styles.bg_img_two}`} />
          <div className={`${styles.bg_img} ${styles.bg_img_three}`} />
          <h1>Order A Delivery From Your Favourite Restaurants</h1>
          <p>Order Yummy Fill Your Tummy</p>
          <div className={styles.cards}>
            <div className={styles.card_one}>
              <img src={orderAlone} alt="Order Alone" />
              <h4>Order Alone</h4>
            </div>
            <div className={styles.card_two}>
              <img src={orderAsGroup} alt="Order As Group" />
              <h4>Order As A Group</h4>
            </div>
          </div>
          <div className={styles.searchbar}>
            <input type="text" placeholder="Enter Your Postcode" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
            <div className={styles.close_btn} onClick={() => setPostcode("")}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ED1E79"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
            </div>
            <Link to={`/search/${postcode}`}>
              <button className={styles.search_btn}>Search</button>
            </Link>
          </div>
        </section>
        <section className={styles.how_it_works}>
          <h2>How it works</h2>
          <p>Get your favourite food in 4 simple steps</p>
          <div className={styles.img_container}>
            <img src={fullCt} alt="FullCT" />
            <div className={styles.content}>
              <div className={styles.upper}>
                <h5>Search</h5>
                <p>Find all restaurants <br /> available near you</p>
              </div>
              <div className={styles.lower}>
                <h5>Choose</h5>
                <p>Browse hundreds of menus <br /> to find the food you like</p>
              </div>
              <div className={styles.upper}>
                <h5>Pay</h5>
                <p>It's quick, secure and easy</p>
              </div>
              <div className={styles.lower}>
                <h5>Enjoy</h5>
                <p>Food is prepared & delivered <br /> to your door</p>
              </div>
            </div>
          </div>
          <div className={styles.mobile_view}>
            <div className={styles.content}>
              <div className={styles.upper}>
                <img src={step1} alt="Step1" />
                <h5>Search</h5>
                <p>Find all restaurants available near you</p>
              </div>
              <div className={styles.lower}>
                <img src={step2} alt="Step1" />
                <h5>Choose</h5>
                <p>Browse hundreds of menus to find the food you like</p>
              </div>
              <div className={styles.upper}>
                <img src={step3} alt="Step1" />
                <h5>Pay</h5>
                <p>It's quick, secure and easy</p>
              </div>
              <div className={styles.lower}>
                <img src={step4} alt="Step1" />
                <h5>Enjoy</h5>
                <p>Food is prepared & delivered to your door</p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.featured}>
          <h1>Featured Restaurants</h1>
          <div className={styles.slider}>
            <Responsive />
          </div>
        </section>
        <section className={styles.reviews}>
          <div className={styles.top_right} />
          <div className={styles.bottom_left} />
          <div className={styles.title}>
            <h1>Our Happy<br />Customers</h1>
            <div className={styles.arrows}>
              <img src={ArrowLeft} alt="Left Arrow" onClick={previousSlide} />
              <img src={ArrowRight} alt="Right Arrow" onClick={nextSlide} />
            </div>
          </div>
          <div className={styles.slider}>
            <ReviewSlider sliderRef={sliderRef} />
          </div>
        </section>
        <section className={styles.app}>
          <h1>To Order Faster. Get The App</h1>
          <p>Your food ordered easier, with all the rewards. ORDER, EAT, REDEEM.</p>
          <div className={styles.buttons}>
            <button className={styles.android}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill="white"><path d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"/></svg>
              available on<br />Google Store
            </button>
            <button className={styles.android}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="white"><path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/></svg>
              available on<br />Apple Store
            </button>
          </div>
        </section>
      </div>
    </React.Fragment>
  )
}

export default Home
