import { all, fork } from "redux-saga/effects"
//public
import AccountSaga from "./account/saga"
import RestaurantSaga from "./restaurant/saga"
import CustomerSaga from './customer/saga'

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(RestaurantSaga),
    fork(CustomerSaga),
  ])
}
