import React from "react";
import Slider from "react-slick";

import "/node_modules/slick-carousel/slick/slick.css"; 
import "/node_modules/slick-carousel/slick/slick-theme.css";

import styles from './style.module.scss';

const ReviewSlider = ({ sliderRef }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true
  };

  return (
    <div>
      <Slider {...settings} ref={sliderRef}>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <p>Amazing customer service! I recently ordered food but the order was cancelled but I was still charged... contacted Yummy customer service and the situation was handled online in less than 5 minutes... fantastic response time and very considerate staff.</p>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <p>Amazing customer service! I recently ordered food but the order was cancelled but I was still charged... contacted Yummy customer service and the situation was handled online in less than 5 minutes... fantastic response time and very considerate staff.</p>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default ReviewSlider;
