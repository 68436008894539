import React from "react";
import MetaTags from "react-meta-tags"
import styles from '../assets/styles/receipt.module.scss';
import { Button } from 'antd';
import {PrinterOutlined} from '@ant-design/icons'

const Receipt = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Receipt</title>
      </MetaTags>
      <div className={styles.main_container}>
        <div className={styles.banner}>
          <h1>Thank You</h1>
          <p>Your order has been placed.</p>
        </div>

        <div className={styles.results}>
          <div className={styles.result_content}>
            <div className={styles.header}>
              <label>Order Details</label>
            </div>
            <div className={styles.box_gray}>
              <h3 className={styles.title}>Thank you</h3>

              <div className={styles.item_gray}>
                <label>Customer Name</label>
                <label>Denis Kravchenko</label>
              </div>

              <div className={styles.item}>
                <label>Contact Number</label>
                <label>+79506739839</label>
              </div>

              <div className={styles.item_gray}>
                <label>Merchant Name</label>
                <label>Yummy Takeaways Coventry TEST SHOP</label>
              </div>

              <div className={styles.item}>
                <label>Telephone</label>
                <label>0203 4757222</label>
              </div>

              <div className={styles.item_gray}>
                <label>Address</label>
                <label>571 Stoney Stanton Road Coventry West Midlands CV6 5GA</label>
              </div>

              <div className={styles.item}>
                <label>Order Type</label>
                <label>pickup</label>
              </div>

              <div className={styles.item_gray}>
                <label>Payment Type</label>
                <label>Cash On Pickup</label>
              </div>

              <div className={styles.item}>
                <label>Reference No</label>
                <label>45422</label>
              </div>

              <div className={styles.item_gray}>
                <label>Order Date</label>
                <label>Jun 06,2023 8:59:35</label>
              </div>

              <div className={styles.item}>
                <label>Pickup Date</label>
                <label>06-06-2023</label>
              </div>

              <p className={styles.item_title}>Pizza</p>
                <div className={styles.item}>
                    <label>1 Chicken Tikka Pizza 10 </label>
                    <label>£5.00</label>
                </div>

                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>thisisatestaddon</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.10</label>
                    </div>
                </div>
                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>tuyentest</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.21
                        </label>
                    </div>
                </div>
                <div className={styles.sub_item}>
                    <label className={styles.sub_title}>newcategory</label>
                    <div className={styles.sub_price}>
                        <label>1 x option1</label>
                        <label>£0.10
                        </label>
                    </div>
                </div>
                <hr></hr>
                <div className={styles.price_container}>
                    <p className={styles.sub_total}>Sub Total :</p>
                    <p className={styles.sub_total}>£5.41</p>
                </div>
                <div className={styles.price_container}>
                    <p className={styles.total}>Total :</p>
                    <p className={styles.total}>£5.41</p>
                </div>
                <p> Earn Points & Use Them Towards Your Orders </p>

            </div>

            <div className={styles.footer}>
              <Button>Track Order</Button>
              <PrinterOutlined className={styles.print}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

export default Receipt
