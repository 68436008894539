import React from "react";
import MetaTags from "react-meta-tags"
import styles from '../assets/styles/review.module.scss';
import YummyProgress from '../components/YummyProgress'
import { CustomButton } from '../components/Custom/CustomButton'
import { useHistory } from "react-router-dom";
import OrdersPaymentOption from '../components/OrdersPaymentOption'

const ReviewOrder = () => {
    const history = useHistory()
    return (
        <React.Fragment>
            <MetaTags>
                <title>Yummy Takeaways | Review Order</title>
            </MetaTags>
            <div className={styles.main_container}>
                <div className={styles.banner}>
                    <h1>Confirm & Pay</h1>
                    <p>please review your order below</p>
                </div>
                <YummyProgress />
                <div className={styles.results}>
                    <div className={styles.backBtn}>
                        <CustomButton width={130} height={33} background="#EB1E78" onClick={() => history.push('/yummytestshop')}>Back</CustomButton>
                    </div>
                    <div className={styles.result_content}>
                        <div className="row">
                            <div className="col-md-7">
                                <div className={styles.result_left}>
                                    <div className={styles.payinfo}>
                                        <p>Payment Information</p>
                                        <hr />
                                    </div>

                                    <div className={styles.payinfor_detail}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>Merchant Name</label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>: Yummy Takeaways Coventry TEST SHOP</label>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>

                                    <div className={styles.payinfor_detail}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>Pickup Date	</label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>: Jun,06,2023</label>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>

                                    <div className={styles.payinfor_detail}>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <label>Delivery Time</label>
                                            </div>
                                            <div className="col-md-6">
                                                <label>: ASAP</label>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                    </div>

                                    <div className={styles.payinfo}>
                                        <p>Pickup Address</p>
                                        <hr />
                                    </div>

                                    <p style={{fontSize: 14}}>571 Stoney Stanton Road Coventry West Midlands CV6 5GA</p>

                                    <div className={styles.payinfo}>
                                        <p>Payment Method</p>
                                        <hr />
                                    </div>
                                    <p style={{fontSize: 14}}>Cash On Pickup</p>

                                    <div className = {styles.confirmOrderBtn}>
                                        <CustomButton width={160} height={38} background="#00b279" onClick={() => history.push('/receipt')}>Confirm Order</CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className={styles.result_right}>
                                    <div className={styles.header}>
                                        <p>Your Order</p>
                                    </div>
                                    <div className={styles.order_container}>
                                        <OrdersPaymentOption />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </React.Fragment>

    )
}

export default ReviewOrder
