import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  background: "#BF4F74" 
  padding: 15px 10px;
  border: 1px solid #c9c7c7;
  background: #f0f0f0;
  height: 38px;
  &:focus {
    outline: none;
  }
`;

export const CountrySelector = styled.select`
  width: 100%;
  background: #f0f0f0;
  border: 1px solid #c9c7c7;
  color: #1e1e1e;
  padding: 8px 10px;
  &:focus {
    outline: none;
  }
`;

export const CountryOption = styled.option`
  font-size: 14px;
`;