import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from '../../assets/styles/header.module.scss';
import topSecretTop from '../../assets/images/top-secret-top.png';
import logo from '../../assets/images/yummylogo.png';
import { useSelector, useDispatch } from "react-redux"
import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useHistory } from "react-router-dom";
import { setAuthUser } from '../../store/actions'

const Header = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const { authUser } = useSelector(state => state.Account)

  const onSignOut = () => {
    localStorage.removeItem('accessToken')
    dispatch(setAuthUser(null))
    history.push('/login')
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className={styles.header}>
        <div className={styles.earn_points}>
          <img src={topSecretTop} alt="top" />
          <h2 className={styles.get_now}>Get Now</h2>
          <p className={styles.earn_title}>Earn 100 Points On Your First Order</p>
          <button className={styles.order_now}>
            Order Now
          </button>
        </div>
        <nav className={styles.navigation}>
          <Link to="/">
            <img src={logo} alt={logo} className={styles.logo} />
          </Link>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/merchant-signup">Restaurant Signup</Link>
            </li>
            {authUser && (
              <>
                <li>
                  <Button type="primary" icon={<UserOutlined />} size={'large'} style={{ background: "#00b279", borderColor: "#00b279" }}>
                    {authUser.First_Name}
                  </Button>
                </li>
                <li>
                  <div onClick={onSignOut} style={{color: '#ED1E79', fontSize: '1.25em', cursor:'pointer'}}>SignOut</div>
                </li>
              </>
            )}
            {!authUser && (
              <li className={styles.login}>
                <Link to="/login">Login & Signup</Link>
              </li>
            )}
          </ul>
          <div className={styles.hamburger} onClick={() => { setIsOpen(!isOpen) }}>
            <div />
            <div />
            <div />
          </div>
        </nav>
        {isOpen && (
          <div className={styles.dropdown}>
            <div className={styles.item}>
              <Link to="/">Home</Link>
            </div>
            <div className={styles.item}>
              <Link to="/restraunt/signup">Restraunt Signup</Link>
            </div>
            {authUser && (
              <>
                <div className={styles.item}>
                  <Button type="primary" icon={<UserOutlined />} size={'large'} style={{ background: "#00b279", borderColor: "#00b279" }}>
                    {authUser.First_Name}
                  </Button>
                </div>

                <div className={styles.item}>
                  <div onClick={onSignOut} style={{color: '#ED1E79', fontSize: '1.25em', cursor: 'pointer'}}>SignOut</div>
                </div>
              </>
            )}
            {!authUser && (
              <div className={`${styles.item} ${styles.login_btn}`}>
                <Link to="/login">Login & Signup</Link>
              </div>
            )}

          </div>
        )}
      </header>
    </React.Fragment>
  )
}

export default Header
