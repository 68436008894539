import React from 'react';
import { Checkbox, Radio } from 'antd';

import styles from './style.module.scss'; 

const FilterComp = () => {
  return (
    <div className={styles.wrapper}>
      <h3>Filters</h3>
      <section className={styles.search}>
        <div className={styles.title}>
          <p>Search by name</p>
          <div />
        </div>
        <input className={styles.searchbar} type="text" placeholder='enter restaurant name' />
      </section>
      <section className={styles.fee}>
        <div className={styles.title}>
          <p>Delivery Fee</p>
          <div />
        </div>
        <div className={styles.checkbox_container}>
          <Checkbox>Free Delivery</Checkbox>
        </div>
      </section>
      <section className={styles.delivery}>
        <div className={styles.title}>
          <p>By Delivery</p>
          <div />
        </div>
        <div className={styles.radio_container}>
          <Radio>Delivery & Pickup</Radio>
          <Radio>Delivery Only</Radio>
          <Radio>Pickup Only</Radio>
          <Radio>Delivery / Pickup / Dinein</Radio>
          <Radio>Delivery & Dinein</Radio>
          <Radio>Pickup & Dinein</Radio>
          <Radio>Dinein Only</Radio>
        </div>
      </section>
      <section className={styles.type}>
        <div className={styles.title}>
          <p>Delivery Fee</p>
          <div />
        </div>
        <div className={styles.checkbox_container}>
          <Checkbox>American</Checkbox>
          <Checkbox>Indian</Checkbox>
          <Checkbox>Sandwiches</Checkbox>
          <Checkbox>Italian</Checkbox>
          <Checkbox>Burgers</Checkbox>
          <Checkbox>Pizza</Checkbox>
          <Checkbox>Vegetarian</Checkbox>
          <Checkbox>Desserts</Checkbox>
          <Checkbox>Waffles</Checkbox>
          <Checkbox>American</Checkbox>
          <Checkbox>Indian</Checkbox>
          <Checkbox>Sandwiches</Checkbox>
          <Checkbox>Italian</Checkbox>
          <Checkbox>Burgers</Checkbox>
          <Checkbox>Pizza</Checkbox>
          <Checkbox>Vegetarian</Checkbox>
          <Checkbox>Desserts</Checkbox>
          <Checkbox>Waffles</Checkbox>
        </div>
      </section>
    </div>
  )
}

export default FilterComp