import MetaTags from "react-meta-tags"
import React, { useState } from "react";
import styles from '../../assets/styles/auth.module.scss';
import { Form, Alert, Modal, Button } from 'antd';
import { Input, CountrySelector, CountryOption } from '../../components/Custom/YummyInput'
import { CustomButton } from '../../components/Custom/CustomButton'
import { countries } from '../../constants/common'
import { axiosApi } from '../../helpers/api_helper'
import * as url from "../../helpers/url_helper";

const MerchantSignUp = () => {
  const [form] = Form.useForm()
  const [selectedCountry, setSelectedCountry] = useState('');
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)


  const onFinish = (values) => {
    console.log('values =>', values)
    setLoading(true)
    axiosApi.post(url.RESTAURANT_SIGNUP, values).then(res => {
      setLoading(false)
      if (res.data.success) {
        setError(false)
        setSuccess(true)
        form.resetFields();
      }
    })
      .catch(error => {
        setLoading(false)
        setError(error?.response?.data)
      })
    // dispatch(signUpRestaurant(values))
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleOk = () => {
    setSuccess(false);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Merchant SignUp</title>
      </MetaTags>
      <div className={styles.main_container}>
        <div className={styles.banner}>
          <h1>Restaurant Signup</h1>
        </div>
        <div className={styles.tabs}>
          <ul>
            <li className={styles.browse}>Merchant information</li>
          </ul>
        </div>
        <div className={styles.login_form_container}>
          <div className={styles.container}>

            <div className="row">
              <div className={styles.login_form}>
                {error && (
                  <Alert
                    message="Error Text"
                    description={error}
                    type="error"
                    closable
                    onClose={() => { }}
                  />
                )}

                {success && (
                  <Modal title="Success" 
                  open={success} 
                  onOk={handleOk}
                  footer={[
                    <Button key="back" onClick={handleOk}>
                      Ok
                    </Button>,
                  ]}
                  >
                   {'Restaurant has been created successfully! Please contact with support'}
                  </Modal>
                )}

                <div className={styles.login_form_content}>
                  <Form
                    name="basic"
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                  >
                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Shop Name</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="ShopName"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Building Name</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="BuildingName"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Street</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="Street"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>City</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="City"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Country</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="Country"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <CountrySelector value={selectedCountry} onChange={handleCountryChange}>
                            <CountryOption value="">Select a country</CountryOption>
                            {countries.map((country) => (
                              <CountryOption key={country.code} value={country.code}>
                                {country.name}
                              </CountryOption>
                            ))}
                          </CountrySelector>
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>


                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Post code/Zip code</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="PostCode"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Tel1</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="Tel1"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3">
                        <label>Tel2</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="Tel2"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="text" placeholder="" />
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>


                    <div className="row" >
                      <div className="col-md-3">
                        <label style={{ marginTop: 10 }}>Email</label>
                      </div>
                      <div className="col-md-8">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'Please fill out this field!',
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                        >
                          <Input type="email" placeholder="" />
                        </Form.Item>
                        Important: Please enter your correct email. we will sent an activation code to your email
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-md-3"></div>
                      <div className="col-md-8" style={{ marginTop: 20 }}>
                        <Form.Item>
                          <CustomButton type="primary" htmlType="submit" width={120} height={40} background={'#EB1E78'}>
                            Next
                          </CustomButton>
                        </Form.Item>
                      </div>
                      <div className="col-md-1"></div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="col-md-4">

              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchantSignUp
