import {
  SIGNUP_CUSTOMER,
  SIGNUP_CUSTOMER_SUCCESS,
  SIGNUP_CUSTOMER_FAIL
} from "./actionTypes";

const INIT_STATE = {
  loading: false,
  customer: null,
  customers: [],
  error: null,
  success: false,
  message: null
};

const customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_CUSTOMER:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        restaurant: action.payload
      };

    case SIGNUP_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default customer;
