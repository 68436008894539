import { call, put, takeEvery } from "redux-saga/effects"
import { SIGNUP_RESTAURANT} from "./actionTypes"

import {signUpRestaurantSuccess, signUpRestaurantFail} from "./actions"
import { signUpRestaurantAsync } from "../../helpers/backend_helper"


function* signUpRestaurant({payload: values}) {
  try {
    const response = yield call(signUpRestaurantAsync, values)
    if(response.success){
      yield put(signUpRestaurantSuccess(response.result))
    }
  } catch (error) {
    console.log('error =>',error)
    yield put(signUpRestaurantFail(error.err))
  }
}

function* RestaurantSaga() {
  yield takeEvery(SIGNUP_RESTAURANT, signUpRestaurant)
}

export default RestaurantSaga
