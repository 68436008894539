import React, { useRef, useState, useEffect } from 'react';
import MetaTags from "react-meta-tags";
import styles from '../assets/styles/testshop.module.scss';
import starimg from '../assets/images/star-on.png';
import avatarimg from '../assets/images/avatar.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Modal from 'react-modal';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import YummyProgress from '../components/YummyProgress';

const YummyTestShop = () => {

    const pizzaSectionRef = useRef(null);

    const scrollToPizza = () => {
        pizzaSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const burgersSectionRef = useRef(null);

    const scrollToBurgers = () => {
        burgersSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const dealsSectionRef = useRef(null);

    const scrollToDeals = () => {
        dealsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const [count, setCount] = useState(0);

    const increment = () => {
        setCount(count + 1);
    };

    const decrement = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 500) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isclicked, setIsclicked] = useState(false);
    const clickedWrap = () => {
        setIsclicked(!isclicked);
    };

    const [isclicked1, setIsclicked1] = useState(true);
    const clickedWrap1 = () => {
        setIsclicked1(!isclicked1);
    };

    const [isclicked2, setIsclicked2] = useState(true);
    const clickedWrap2 = () => {
        setIsclicked2(!isclicked2);
    };



    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const [isOpen1, setIsOpen1] = useState(false);

    const handleToggle1 = () => {
        setIsOpen1(!isOpen1);
    };

    const [isOpen2, setIsOpen2] = useState(false);

    const handleToggle2 = () => {
        setIsOpen2(!isOpen2);
    };

    // const [isOpen22, setIsOpen22] = useState(false);
    // const [selectedOption, setSelectedOption] = useState('');

    // const toggleDropdown = () => {
    //     setIsOpen22(!isOpen22);
    // };

    // const selectOption = (option) => {
    //     setSelectedOption(option);
    //     setIsOpen22(false);
    // };



    return (
        <React.Fragment>
            <MetaTags>
                <title>Yummy Takeaways | Online Ordering & Takeaways</title>
            </MetaTags>
            <section id='innerpages' className={styles.innerbanner}>
                <div className={styles.container}>
                    <div className={styles.bannercontent}>
                        <div className={styles.bannerlogo}>
                            <span></span>
                        </div>
                        <div className={styles.ratingetcsec}>
                            <ul>
                                <li>
                                    <ol>
                                        <li><img src={starimg} alt=''></img></li>
                                        <li><img src={starimg} alt=''></img></li>
                                        <li><img src={starimg} alt=''></img></li>
                                        <li><img src={starimg} alt=''></img></li>
                                        <li><img src={starimg} alt=''></img></li>
                                    </ol>
                                </li>
                                <li>
                                    <div className={styles.reviewbtn}>
                                        <Link><span>1</span> Reviews</Link>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.shopstatus}>
                                        <span>Open</span>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.minorder}>
                                        <span>Minimum Order: <em>£0.00</em></span>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.addfav}>
                                        <Link title='add to your favorite restaurant'><FontAwesomeIcon icon={faHeart} /></Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <h1>Yummy Takeaways Coventry TEST SHOP</h1>
                        <p><FontAwesomeIcon icon={faLocationDot} /> <span>571 Stoney Stanton Road Coventry West Midlands CV6 5GA</span></p>
                        <p>Indian, Burgers</p>
                        {/* <div className="dropdown">
                            <button className="dropdown-toggle" onClick={toggleDropdown}>
                                {selectedOption || 'Select an option'}
                            </button>
                            {isOpen22 && (
                                <ul className="dropdown-menu">
                                    <li onClick={() => selectOption('Option 1')}>Option 1</li>
                                    <li onClick={() => selectOption('Option 2')}>Option 2</li>
                                    <li onClick={() => selectOption('Option 3')}>Option 3</li>
                                </ul>
                            )}
                        </div> */}
                    </div>
                </div>
            </section>

             <YummyProgress />           
            <section id='middlesection' className={styles.middlesection}>
                <div className={styles.container}>
                    <div className={styles.flexgrid}>
                        <div className={styles.col8}>
                            <div className={styles.tabsbox}>
                                <Tabs>
                                    <TabList className={styles.reactlist}>
                                        <Tab className={styles.listtabs}><span>Menu</span> <i><FontAwesomeIcon icon={faUtensils} /></i></Tab>
                                        <Tab className={styles.listtabs}><span>Opening Hours</span> <i><FontAwesomeIcon icon={faClock} /></i></Tab>
                                        <Tab className={styles.listtabs}><span>Reviews</span> <i><FontAwesomeIcon icon={faStarHalfStroke} /></i></Tab>
                                        <Tab className={styles.listtabs}><span>Map</span> <i><FontAwesomeIcon icon={faLocationArrow} /></i></Tab>
                                        <Tab className={styles.listtabs}><span>Photos</span> <i><FontAwesomeIcon icon={faImage} /></i></Tab>
                                        <Tab className={styles.listtabs}><span>Information</span> <i><FontAwesomeIcon icon={faCircleInfo} /></i></Tab>
                                    </TabList>
                                    <div className={styles.tabspanelwrap}>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Menu</h2>
                                                <div className={styles.menuwrap}>
                                                    <div className={styles.menulist}>
                                                        <div className={isScrolled ? 'scrolled' : ''}>
                                                            <ul>
                                                                <li>
                                                                    <Link onClick={scrollToPizza}>Pizza <span>(5)</span> <i><FontAwesomeIcon icon={faChevronRight} /></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link onClick={scrollToBurgers}>Burgers <span>(2)</span> <i><FontAwesomeIcon icon={faChevronRight} /></i></Link>
                                                                </li>
                                                                <li>
                                                                    <Link onClick={scrollToDeals}>Best Pizza Deals <span>(1)</span> <i><FontAwesomeIcon icon={faChevronRight} /></i></Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={styles.menucontent}>
                                                        <Link>Change Address</Link>
                                                        <div className='accordianwrapper'>
                                                            <div ref={pizzaSectionRef} className={styles.menuboxwrap}>
                                                                <div className="accordion">
                                                                    <div className="accordion-header" onClick={handleToggle}>
                                                                        <div className='clickedwrap' onClick={clickedWrap}>
                                                                            <h3>Pizza</h3>
                                                                            <div className={isclicked ? 'inactive' : 'active'}>
                                                                                <span className="expand">
                                                                                    <span>Tap To Expand </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronDown} /></i>
                                                                                </span>
                                                                                <span className="expand hide">
                                                                                    <span>Tap To Hide </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {!isOpen && (
                                                                        <div className="accordion-content">
                                                                            <p>Full range of Pizza's freshly made in store</p>
                                                                            <div className={styles.menubox}>
                                                                                <div className={styles.menuhead}>
                                                                                    <h5>Chicken Tikka Pizza 10"</h5>
                                                                                    <span>Chicken Tikka and Cheese</span>
                                                                                </div>
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>small</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£5.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link onClick={openModal}><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>medium</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£6.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>large</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£7.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className={styles.menubox}>
                                                                                <div className={styles.menuhead}>
                                                                                    <h5>Punjabi Pizza</h5>
                                                                                </div>
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>small</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£5.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>medium</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£6.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>large</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£7.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className={styles.menubox}>
                                                                                <div className={styles.menuhead}>
                                                                                    <h5>Ahmed Pizza</h5>
                                                                                </div>
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>small</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£5.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>medium</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£6.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>large</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£7.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div ref={burgersSectionRef} className={styles.menuboxwrap}>
                                                                <div className="accordion">
                                                                    <div className="accordion-header" onClick={handleToggle1}>
                                                                        <div className='clickedwrap' onClick={clickedWrap1}>
                                                                            <h3>Burgers</h3>
                                                                            <div className={isclicked1 ? 'inactive' : 'active'}>
                                                                                <span className="expand">
                                                                                    <span>Tap To Expand </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronDown} /></i>
                                                                                </span>
                                                                                <span className="expand hide">
                                                                                    <span>Tap To Hide </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {isOpen1 && (
                                                                        <div className="accordion-content">
                                                                            <div className={styles.menubox}>
                                                                                <div className={styles.menuhead}>
                                                                                    <h5>Cheese Burger</h5>
                                                                                </div>
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>Meal</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£3.55</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>medium</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£5.75</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>large</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£7.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div ref={dealsSectionRef} className={styles.menuboxwrap}>
                                                                <div className="accordion">
                                                                    <div className="accordion-header" onClick={handleToggle2}>
                                                                        <div className='clickedwrap' onClick={clickedWrap2}>
                                                                            <h3>Deals</h3>
                                                                            <div className={isclicked2 ? 'inactive' : 'active'}>
                                                                                <span className="expand">
                                                                                    <span>Tap To Expand </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronDown} /></i>
                                                                                </span>
                                                                                <span className="expand hide">
                                                                                    <span>Tap To Hide </span>
                                                                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {isOpen2 && (
                                                                        <div className="accordion-content">
                                                                            <div className={styles.menubox}>
                                                                                <div className={styles.menuhead}>
                                                                                    <h5>Best Pizza Deals</h5>
                                                                                </div>
                                                                                <ul>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>Meal</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£3.55</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>medium</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£5.75</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                    <li>
                                                                                        <div className={styles.size}>
                                                                                            <span>large</span>
                                                                                        </div>
                                                                                        <div className={styles.price}>
                                                                                            <span>£7.00</span>
                                                                                        </div>
                                                                                        <div className={styles.addcartbtn}>
                                                                                            <Link><span><FontAwesomeIcon icon={faPlus} /></span></Link>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Opening Hours</h2>
                                                <div className={styles.hours}>
                                                    <ul>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>monday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>0:00 - 23:45</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>tuesday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>1:00 - 6:55</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>wednesday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>2:00 - 1:10</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>thursday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>1:00 - 23:59</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>friday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>7:40 - 0:45</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>saturday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>9:40 - 19:55</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className={styles.day}>
                                                                <i><FontAwesomeIcon icon={faPlus} /></i>
                                                                <span>sunday</span>
                                                            </div>
                                                            <div className={styles.time}>
                                                                <span>8:00 - 2:59</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Reviews</h2>
                                                <div className={styles.reviewboxwrap}>
                                                    <div className={styles.reviewbox}>
                                                        <div className={styles.reviewimg}>
                                                            <img src={avatarimg} alt=''></img>
                                                        </div>
                                                        <div className={styles.review}>
                                                            <div className={styles.ratingyears}>
                                                                <ul className={styles.rating}>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                </ul>
                                                                <span>3 years ago</span>
                                                            </div>
                                                            <p>Very good place for pizza, fast delivery as well.</p>
                                                        </div>
                                                    </div>
                                                    <div className={styles.reviewbox}>
                                                        <div className={styles.reviewimg}>
                                                            <img src={avatarimg} alt=''></img>
                                                        </div>
                                                        <div className={styles.review}>
                                                            <div className={styles.ratingyears}>
                                                                <ul className={styles.rating}>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                </ul>
                                                                <span>3 years ago</span>
                                                            </div>
                                                            <p>Very good place for pizza, fast delivery as well.</p>
                                                        </div>
                                                    </div>
                                                    <div className={styles.reviewbox}>
                                                        <div className={styles.reviewimg}>
                                                            <img src={avatarimg} alt=''></img>
                                                        </div>
                                                        <div className={styles.review}>
                                                            <div className={styles.ratingyears}>
                                                                <ul className={styles.rating}>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                    <li><FontAwesomeIcon icon={faStar} /></li>
                                                                </ul>
                                                                <span>3 years ago</span>
                                                            </div>
                                                            <p>Very good place for pizza, fast delivery as well.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Map</h2>
                                                <div className={styles.mapcontent}>
                                                    <div className={styles.mapwrap}>
                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d5830.078136162874!2d-1.5138979999896427!3d52.41372651554475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1685701852111!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                    </div>
                                                    <form>
                                                        <input type='text'></input>
                                                        <select>
                                                            <option>Driving</option>
                                                            <option>Walking</option>
                                                            <option>Bicycling</option>
                                                            <option>Transit</option>
                                                        </select>
                                                        <Button type='button'>Get directions</Button>
                                                    </form>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Photos</h2>
                                                <p>Gallery not available</p>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={styles.tabpanel}>
                                            <div className={styles.panelcontent}>
                                                <h2>Information</h2>
                                                <p>Information not available</p>
                                            </div>
                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                        <div className={styles.col4}>
                            <div className={isScrolled ? 'scrolled' : ''}>
                                <div className={styles.delinfobox}>
                                    <div className={styles.innerbox}>
                                        <h2>Delivery Information</h2>
                                        <p>Distance: <span>not available</span></p>
                                        <ul className={styles.modinforul}>
                                            <li>Distance: <span>not available</span></li>

                                            <li>Delivery Est:<span>45-60 Minutes</span></li>

                                            <li>Delivery Distance Covered:<span>not available</span></li>

                                            <li>Delivery Fee:<span>Free Delivery</span></li>
                                        </ul>
                                        <Link>[Change Your Address here]</Link>
                                    </div>
                                    <div className={styles.boxborder}>
                                        <i className={styles.boxicon}></i>
                                        <h2>Your Order</h2>
                                        <div className='itemorderwrap'>
                                            <p>No Item added yet!</p>
                                        </div>
                                    </div>
                                    <div className={styles.boxborder}>
                                        <i className={styles.boxicon2}></i>
                                        <h2>I Want To Order For</h2>
                                        <div className={styles.radiowrap}>
                                            <div className={styles.radio}>
                                                <input type="radio" id="delivery" name='orderopt' value="Delivery"></input>
                                                <label htmlFor="delivery">Delivery</label>
                                            </div>
                                            <div className={styles.radio}>
                                                <input type="radio" name='orderopt' id="pickup" value="Pickup"></input>
                                                <label htmlFor="pickup">Pickup</label>
                                            </div>
                                        </div>
                                        <div className={styles.checkboxwrap}>
                                            <input type="checkbox" id="Delivery" name="Delivery" value="Delivery"></input>
                                            <label htmlFor="Delivery"> Delivery ASAP?</label>
                                        </div>
                                        <Button disabled>Checkout</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className='modalhead'>
                    <h4>Chicken Tikka Pizza 10"</h4>
                    <h5>Chicken Tikka and Cheese</h5>
                    <i onClick={closeModal} className={'closeicon'}><FontAwesomeIcon icon={faCircleXmark} /></i>
                </div>
                <div className='modalbody'>
                    <div className='modalbodycontent'>
                        <div className='bodycontenttop'>
                            <span>Price</span>
                            <i></i>
                        </div>
                        <div className='bodycontentbody'>
                            <ul>
                                <li>
                                    <input type="radio" id="price1" name="price" value=""></input>
                                    <label htmlFor="price1">small £5.00</label>
                                </li>
                                <li>
                                    <input type="radio" id="price2" name="price" value=""></input>
                                    <label htmlFor="price2">medium £6.00</label>
                                </li>
                                <li>
                                    <input type="radio" id="price3" name="price" value=""></input>
                                    <label htmlFor="price3">large £7.00</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='modalbodycontent'>
                        <div className='bodycontenttop'>
                            <span>Quantity</span>
                            <i></i>
                        </div>
                        <div className='bodycontentbody'>
                            <div className='counterwrap'>
                                <button onClick={decrement}>-</button>
                                <span>{count}</span>
                                <button onClick={increment}>+</button>
                            </div>
                        </div>
                    </div>
                    <div className='accrodianwrap'>
                        <Accordion>
                            <AccordionItem header="thisisatestaddon (Click to view)" initialEntered>
                                <div className='accrodiantable'>
                                    <table>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </AccordionItem>

                            <AccordionItem header="thisisatestaddon (Click to view)">
                                <div className='accrodiantable'>
                                    <table>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </AccordionItem>

                            <AccordionItem header="thisisatestaddon (Click to view)">
                                <div className='accrodiantable'>
                                    <table>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="radio" id="Option1" name="item" value=""></input>
                                                <label htmlFor="Option1">Option1</label>
                                            </td>
                                            <td>
                                                <h6>£0.10</h6>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <div className='modalfooter'>
                    <button className='closebtn' onClick={closeModal}>Close Modal</button>
                    <button type='submit'>Add to Cart</button>
                </div>

            </Modal>

        </React.Fragment>
    )
}

export default YummyTestShop