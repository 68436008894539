import React from "react";
import Slider from "react-slick";
import { Rate } from 'antd';

import "/node_modules/slick-carousel/slick/slick.css"; 
import "/node_modules/slick-carousel/slick/slick-theme.css";

import styles from './style.module.scss';

import banner from '../../assets/images/banner-2.jpg';
import masalajacks from '../../assets/images/masalajacks.gif';

const ResponsiveSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
        <div className={styles.card_container}>
          <div className={styles.card}>
            <img className={styles.banner} src={banner} alt="Banner" />
            <img className={styles.logo} src={masalajacks} alt="Logo" />
            <button className={styles.pre_order_btn}>Pre-Order</button>
            <div className={styles.description}>
              <div className={styles.header}>
                <h5>Masala Jacks</h5>
                <Rate disabled defaultValue={5} />
              </div>
              <p>Indian, Halal, Pakistani, Curries</p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default ResponsiveSlider;
