import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Login Method
const signUpRestaurantAsync = (data) => post(url.RESTAURANT_SIGNUP, data);
const signUpCustomerAsyc = (data) => post(url.CUSTOMER_SIGNUP, data);

// Verify
// export const postVerify = (data) => get(`/verify-email/${data}`);
// // postForgetPwd

// // update Event
// export const updateEvent = (event) => put(url.UPDATE_EVENT, event);

// dateUser = (user) => put(url.UPDATE_USER, user);

// // delete user
// export const deleteUser = (user) => del(url.DELETE_USER, { headers: { user } });


// export const topSellingData = (month) =>
//   get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } });

export {
  signUpRestaurantAsync,
  signUpCustomerAsyc
};
