import Home from "../pages/Home"
import Search from "../pages/Search"
import Login from '../pages/Authentication/Login'
import PrivacyAndPolicy from '../pages/PrivacyAndPolicy'
import Profile from '../pages/Profile'
import MerchantSignup from '../pages/Authentication/MerchantSignup'
import YummyTestShop from "../pages/YummyTestShop";
import PaymentOption from '../pages/PaymentOption';
import ReviewOrder from '../pages/ReviewOrder';
import Receipt from '../pages/Receipt';

const AuthRoutes = [
  { path: "/search/:query", component: Search },
  { path: '/profile', component: Profile},
  { path: '/yummytestshop', component: YummyTestShop},
  { path: '/payment-option', component: PaymentOption},
  { path: '/review-order', component: ReviewOrder},
  { path: '/receipt', component: Receipt},
]

const NonAuthRoutes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: '/login', component: Login},
  { path: '/privacyandpolicy', component: PrivacyAndPolicy},
  { path: '/merchant-signup', component: MerchantSignup},

]

export { AuthRoutes, NonAuthRoutes }
