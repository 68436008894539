import styled from 'styled-components';

export const CustomButton = styled.button`
  width: ${props => props?.width}px;
  height: ${props => props?.height}px;
  text-decoration: none;
  color: ${props => props?.color ? props.color : '#ffffff'};
  background: ${props => props?.background ? props.background : '#23c562'};
  border: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;

`;

export const SignUpButton = styled.button`
  width: 100%;
  height: ${props => props?.height}px;
  text-decoration: none;
  color: ${props => props?.color ? props.color : '#ffffff'};
  background: ${props => props?.background ? props.background : '#ff4e41'};
  border: 1px solid #ff4e41;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;



