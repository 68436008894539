import MetaTags from "react-meta-tags"
import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Rate } from 'antd';
import { Dropdown, Button, Modal } from 'antd';
import styles from '../assets/styles/search.module.scss';
import FilterComp from "../components/FilterComp/FilterComp";

import restaurantsData from '../assets/json/restaurants.json';
import bennysImg from '../assets/images/Bennys-Chicken-Coventry.png';
import YummyProgress from "../components/YummyProgress";

const Search = () => {
  const { query } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState("list");

  const items = [
    {
      key: '1',
      label: 'Name',
    },
    {
      key: '2',
      label: 'Rating',
    },
    {
      key: '3',
      label: 'Minimum',
    },
    {
      key: '4',
      label: 'Distance',
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Online Ordering & Takeaways</title>
      </MetaTags>
      <div className={styles.main_container}>
        <div className={styles.banner}>
          <h1>{restaurantsData.restaurants.length} results</h1>
          <p>{query}</p>
        </div>
        <YummyProgress />
        <div className={styles.results}>
          <div className={styles.left}>
            <FilterComp />
            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={360}>
              <FilterComp />
            </Modal>
          </div>
          <div className={styles.right}>
            <div className={styles.btns_container}>
              <Dropdown.Button
                menu={{
                  items,
                  selectable: true,
                  defaultSelectedKeys: ['3'],
                }}
              >
                Sort By
              </Dropdown.Button>
              <Button className={styles.filter_btn} type="primary" onClick={showModal} style={{ background: "#ED1E79" }} icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white" style={{ height: "1rem" }}><path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" /></svg>} />
              <div className={styles.view_btns}>
                <Button className={`${styles.grid_view} ${currentView === "grid" && styles.active}`} onClick={() => setCurrentView("grid")} icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="white" style={{ height: "1.5rem" }}><path d="M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z" /></svg>} />
                <Button className={`${styles.list_view} ${currentView === "list" && styles.active}`} onClick={() => setCurrentView("list")} icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white" style={{ height: "1.5rem" }}><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" /></svg>} />
              </div>
            </div>
            {currentView === "list" ?
              restaurantsData.restaurants.map((restaurant, index) => (
                <div key={index}>
                  <div className={styles.card}>
                    <div className={styles.inner_left}>
                      <img src={bennysImg} alt="Logo" height={120} width={120} />
                      <div className={styles.features}>
                        <div className={styles.feature}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="rgb(51, 212, 19)"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                          <h6>Delivery</h6>
                        </div>
                        <div className={styles.feature}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="rgb(51, 212, 19)"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                          <h6>Pickup</h6>
                        </div>
                      </div>
                      <h6>Cash on delivery available</h6>
                    </div>
                    <div className={styles.inner_middle}>
                      <div className={styles.header}>
                        <Rate disabled defaultValue={5} style={{ marginBottom: 10, marginRight: 30 }} />
                        <span>{restaurant.number_of_reviews} Reviews</span>
                        <span className={styles.status}>Open</span>
                        <span className={styles.min_order}>Minimum Order: ${restaurant.minimum_order}</span>
                      </div>
                      <h4 className={styles.name}>{restaurant.name}</h4>
                      <p className={styles.address}>{restaurant.address}</p>
                      <p className={styles.dishes}>Burgers, Pizza, Halal, Kebabs </p>
                      <p>Distance: {restaurant.distance} miles</p>
                      <p>Delivery Est: {restaurant.delivery_est}</p>
                      <p>Delivery Distance Covered: {restaurant.delivery_distance_covered} miles</p>
                      <p>Delivery Fee: ${restaurant.delivery_fee}</p>
                    </div>
                    <div className={styles.inner_right}>
                      <Link to="/yummytestshop">
                        <button>Order A Collection</button>
                      </Link>
                      <Link to="/yummytestshop">
                        <button>Order A Delivery</button>
                      </Link>
                    </div>
                  </div>
                  <div className={styles.card_mobile}>
                    <div className={styles.inner_left}>
                      <img src={bennysImg} alt="Logo" height={240} width={240} />
                      <h5>Distance: {restaurant.distance}</h5>
                    </div>
                    <div className={styles.inner_right}>
                      <h4 className={styles.name}>{restaurant.name}</h4>
                      <p className={styles.status}>Open</p>
                      <span className={styles.min_order}>Minimum Order: ${restaurant.minimum_order}</span>
                      <p className={styles.dishes}>Burgers, Pizza, Halal, Kebabs </p>
                      <p>Delivery Fee: ${restaurant.delivery_fee}</p>
                      <Rate disabled defaultValue={5} />
                      <div className={styles.buttons}>
                        <Link to="/yummytestshop">
                          <button>Order A Collection</button>
                        </Link>
                        <Link to="/yummytestshop">
                          <button>Order A Delivery</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
              ) : (
                <div className={styles.grid_container}>
                  {restaurantsData.restaurants.map((restaurant, index) => (
                    <div className={styles.grid_card} key={index}>
                      <img src={bennysImg} alt="Logo" height={120} width={120} />
                      <h1 className={styles.name}>{restaurant.name}</h1>
                      <p className={styles.address}>{restaurant.address}</p>
                      <div className={styles.review_min_order}>
                        <div className={styles.one}>
                          <Rate disabled defaultValue={5} />
                          <p>{restaurant.number_of_reviews} Reviews</p>
                        </div>
                        <div className={styles.two}>
                          <p className={styles.amount}>${restaurant.minimum_order}</p>
                          <p>Minimum Order</p>
                        </div>
                      </div>
                      <p className={styles.status}>Open</p>
                      <p className={styles.dishes}>Burgers, Pizza, Halal, Kebabs </p>
                      <p>Distance: {restaurant.distance} miles</p>
                      <p>Delivery Est: {restaurant.delivery_est}</p>
                      <p>Delivery Distance Covered: {restaurant.delivery_distance_covered} miles</p>
                      <p>Delivery Fee: ${restaurant.delivery_fee}</p>
                      <div className={styles.buttons}>
                        <button>Order A Collection</button>
                        <button>Order A Delivery</button>
                      </div>
                    </div>
                  ))}
                </div>
              )}

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Search
