import React from "react"
import { Route } from "react-router-dom"

const NonAuthmiddleware = ({
  component: Component,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
        <Component {...props} />
      )
    }}
  />
)


export default NonAuthmiddleware;
