import {
  SIGNUP_RESTAURANT,
  SIGNUP_RESTAURANT_SUCCESS,
  SIGNUP_RESTAURANT_FAIL
} from "./actionTypes"

export const signUpRestaurant = (values) => ({
  type: SIGNUP_RESTAURANT,
  payload: values,
})

export const signUpRestaurantSuccess = (value) => ({
  type: SIGNUP_RESTAURANT_SUCCESS,
  payload: value,
})

export const signUpRestaurantFail = (error) => ({
  type: SIGNUP_RESTAURANT_FAIL,
  payload: error,
})





