import MetaTags from "react-meta-tags"
import React, { useState } from "react";
import styles from '../../assets/styles/auth.module.scss';
import userLogo from '../../assets/images/user-logo.png';
import passLogo from '../../assets/images/password.png'
import singupLogo from '../../assets/images/signup.png'
import { Form, Alert, notification } from 'antd';
import { Input } from '../../components/Custom/YummyInput'
import { CustomButton, SignUpButton } from '../../components/Custom/CustomButton'
import PhoneInput from "antd-phone-input";
import { useSelector, useDispatch } from "react-redux"
import { setAuthUser } from '../../store/actions'
import { axiosApi } from '../../helpers/api_helper'
import * as url from "../../helpers/url_helper";
import { useHistory } from 'react-router-dom'

const Login = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm()
  const [showForgot, SetshowForgot] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [loading, setLoading] = useState(false)

  const { authUser } = useSelector(state => state.Account)

  const onFinish = async (values) => {
    // dispatch(loginUser(values, props.history))
    setLoading(true)
    axiosApi.post(url.CUSTOMER_LOGIN, values).then(res => {
      setLoading(false)
      if (res.data.auth === true && res.data.accessToken !==null) {
        localStorage.setItem('accessToken', res.data.accessToken)
        axiosApi.defaults.headers.common["Authorization"] = "Bearer " + res.data.accessToken
        dispatch(setAuthUser(res.data.loggedInUser))
        openNotificationSuccess('Customer Successfully Logged IN')
        history.push('/profile')
        form.resetFields();
      } else {
        setErrorMsg('Email Or Password is Wrong')
        openNotificationError('Email Or Password is Wrong')
      }
    })
      .catch(error => {
        setLoading(false)
        setErrorMsg('Email Or Password is Wrong')
        openNotificationError('Email Or Password is Wrong')
      })
  };

  const onFinishSignUp = async (values) => {
    const mobile_num = `+${values.MobileNumber.countryCode + values.MobileNumber.areaCode + values.MobileNumber.phoneNumber}`
    const customerData = {
      RestaurantId: 1,
      First_Name: values.First_Name,
      Last_Name: values.Last_Name,
      Email: values.Email,
      Mobile: mobile_num,
      Password: values.Password,
    }
    if (values.Password !== values.Confirm_Password) {
      openNotificationError('Password is not match!')
    } else {
      setLoading(true)
      axiosApi.post(url.CUSTOMER_SIGNUP, customerData).then(res => {
        setLoading(false)
        if (res.data.success) {
          openNotificationSuccess('Customer was successfully registered!')
          form.resetFields();
        }
      })
        .catch(error => {
          setLoading(false)
          setErrorMsg(error.response?.data)
          openNotificationError(error.response?.data)
        })
    }
  }

  const openNotificationSuccess = (message) => {
    api.success({
      message: `Success`,
      description: message,
      placement: 'topRight',
    });
  };

  const openNotificationError = (message) => {
    api.error({
      message: `Error`,
      description: message,
      placement: 'topRight',
    });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Yummy Takeaways | Login</title>
      </MetaTags>
      <div className={styles.main_container}>
        {contextHolder}
        <div className={styles.banner}>
          <h1>Login & SignUp</h1>
          <p>Sign up to start ordering</p>
        </div>
        <div className={styles.login_form_container}>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className={styles.login_form}>
                  <div className={styles.login_form_header}>
                    <img src={userLogo} alt="loginformlogo" />
                    <label>Log in to your account</label>
                    <hr />
                  </div>
                  <div className={styles.login_form_content}>
                    <Form
                      name="customer-login-form"
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <Form.Item
                        name="Email"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}

                      >
                        <Input type="text" placeholder="Mobile Number or Email" />
                      </Form.Item>

                      <Form.Item
                        name="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}

                      >
                        <Input type="password" placeholder="  Password" />
                      </Form.Item>

                      <div className={styles.login_form_footer}>
                        <label onClick={() => SetshowForgot(true)}>Forgot Password ?</label>
                        <Form.Item>
                          <CustomButton type="primary" htmlType="submit" width={240} height={40}>
                            Login
                          </CustomButton>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                {showForgot && (
                  <div className="mt-4">
                    <div className={styles.login_form}>
                      <div className={styles.login_form_header}>
                        <img src={passLogo} alt="passLogo" />
                        <label>Forgot Password</label>
                        <hr />
                      </div>
                      <div className={styles.login_form_content}>
                        <Form
                          name="forgot-password-form"
                          onFinish={onFinish}
                          autoComplete="off"
                        >
                          <Form.Item
                            name="mobileOrEmail"
                            rules={[
                              {
                                required: true,
                                message: 'Please fill out this field!',
                              },
                            ]}
                            style={{ marginBottom: 10 }}

                          >
                            <Input type="text" placeholder=" Email Address" />
                          </Form.Item>

                          <div className={styles.login_form_footer}>
                            <label onClick={() => SetshowForgot(false)}>Close ?</label>
                            <Form.Item>
                              <CustomButton type="primary" htmlType="submit" width={240} height={40}>
                                Retrieve Password
                              </CustomButton>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>

                )}
              </div>
              <div className="col-md-6">
                <div className={styles.login_form}>
                  <div className={styles.login_form_header}>
                    <img src={singupLogo} alt="singupLogo" />
                    <label>Sign up</label>
                    <hr />
                  </div>
                  <div className={styles.login_form_content}>
                    <Form
                      name="signup-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={onFinishSignUp}
                      autoComplete="off"
                      form={form}
                    >
                      <Form.Item
                        name="First_Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}
                      >
                        <Input type="text" placeholder="First Name" />
                      </Form.Item>

                      <Form.Item
                        name="Last_Name"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}

                      >
                        <Input type="text" placeholder="Last Name" />
                      </Form.Item>

                      <Form.Item
                        name="MobileNumber"
                        style={{ marginBottom: 10, }}

                      >
                        <PhoneInput enableSearch placeholder="Mobile" style={{ background: '#F0F0F0' }} />
                      </Form.Item>

                      <Form.Item
                        name="Email"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}
                      >
                        <Input type="email" placeholder="Email Address" />
                      </Form.Item>

                      <Form.Item
                        name="Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}

                      >
                        <Input type="password" placeholder="Password" />
                      </Form.Item>

                      <Form.Item
                        name="Confirm_Password"
                        rules={[
                          {
                            required: true,
                            message: 'Please fill out this field!',
                          },
                        ]}
                        style={{ marginBottom: 10 }}

                      >
                        <Input type="password" placeholder="Confirm Password" />
                      </Form.Item>

                      <p style={{ color: '#777' }}>By creating an account, you agree to receive sms from vendor.</p>

                      {errorMsg && (
                        <Alert message={errorMsg} type="error" showIcon />
                      )}

                      <Form.Item style={{ marginTop: 20 }}>
                        <SignUpButton type="primary" htmlType="submit" height={40} background={'#ff4e41'}>
                          Create Account
                        </SignUpButton>
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
